<template>
    <y-panel
        :title="element.title"
        :color="element.color"
        :icon="element.icon"
        class="widget-user-welcome col-md-12 col-sm-12 col-center"
    >
        <y-row
            wrap
            justify="center"
            align-items="center"
        >
            <!-- <y-col
                xs="12"
                md="9"
                lg="4"
            >
                <img
                    class="greeting-thumb"
                    src="@/assets/images/welcome.svg"
                    alt="Illustration of User Welcome"
                    draggable="false"
                >
            </y-col> -->
            <y-col
                xs="12"
                md="12"
                lg="6"
            >
                <h4 class="greeting-title">
                    {{ $t('dashboard.widgets.welcome.greeting') }}
                </h4>
                <p class="about-groot">
                    {{ $t('dashboard.widgets.welcome.about_quark') }}
                </p>
                <p class="about-quark">
                    {{ $t('dashboard.widgets.welcome.about_quark_desc') }}
                </p>
            </y-col>
            <y-col
                xs="12"
                md="9"
                lg="6"
            >
                <video
                    width="720"
                    height="420"
                    controls
                >
                    <source
                        src="https://cdn.quarkino.com/panel/intro_panel.mp4"
                        type="video/mp4"
                    >
                </video>
            </y-col>
        </y-row>
        <y-row
            justify="start"
            size="xs"
        >
            <y-button
                with-icon="md-link"
                tag="a"
                size="sm"
                class="blue ml10 mb5"
                href="https://cdn.quarkino.com/panel/?key=docs"
                rel="noreferrer noopener"
                target="_blank"
            >
                {{ $t('dashboard.widgets.welcome.document') }}
            </y-button>
            <y-button
                with-icon="md-link"
                tag="a"
                size="sm"
                class="blue ml10 mb5"
                href="https://cdn.quarkino.com/panel/?key=postman"
                rel="noreferrer noopener"
                target="_blank"
            >
                {{ $t('dashboard.widgets.welcome.postman') }}
            </y-button>
            <y-button
                with-icon="md-link"
                tag="a"
                size="sm"
                class="mb5"
                href="https://cdn.quarkino.com/panel/?key=discord"
                rel="noreferrer noopener"
                target="_blank"
            >
                {{ $t('dashboard.widgets.welcome.discord') }}
            </y-button>
        </y-row>
    </y-panel>
</template>

<script>
    import WidgetMixin from '@/mixins/Widget';

    export default {

        name: 'WelcomeWidget',

        mixins: [WidgetMixin],

        props: {
            /**
             * Widget element
             */
            element: Object,
        },

    };
</script>
